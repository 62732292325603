import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  wrap, subTitle as styledSubTitle, subContents, title as styledTitle, contents,
} from './style.module.css';

const Info = ({
  title,
  subTitle,
  subContent,
  children,
  leftSectionClassNameOnFullWidth = 'col-md-6',
  rightSectionClassNameOnFullWidth = 'col-md-6',
  isFullWidth = false,
}) => (
  <article className={wrap}>
    {
      subTitle && (
        <p
          className={styledSubTitle}
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-duration="300"
          data-sal-easing="ease"
        >
          {subTitle}
        </p>
      )
    }
    <div className="row">
      <div
        className={isFullWidth ? leftSectionClassNameOnFullWidth : 'col-md-12'}
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-duration="300"
        data-sal-easing="ease"
      >
        <h1
          className={styledTitle}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      <div
        className={classNames(isFullWidth ? rightSectionClassNameOnFullWidth : 'col-md-12')}
      >
        <p
          className={contents}
          data-sal="slide-up"
          data-sal-delay="500"
          data-sal-duration="300"
          data-sal-easing="ease"
        >
          {children}
        </p>
        {
          subContent && (
            <p
              className={subContents}
              data-sal="slide-up"
              data-sal-delay="700"
              data-sal-duration="300"
              data-sal-easing="ease"
            >
              {subContent}
            </p>
          )
        }
      </div>
    </div>
  </article>
);

Info.defaultProps = {
  subTitle: '',
  subContent: '',
  isFullWidth: false,
};

Info.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  subContent: PropTypes.string,
  // eslint-disable-next-line react/no-typos
  isFullWidth: PropTypes.boolean,
  children: PropTypes.node.isRequired,
};

export default Info;
