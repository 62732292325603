import styled from 'styled-components';

const Style = {
  section: styled.section`
    width: 100%;
    height: 560px;
    background-color: #9EE4D0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-image: url(${(props) => props.backgroundPCImage});
    @media (max-width: 768px) {
      background-image: url(${(props) => props.backgroundMobileImage});
      height: ${(props) => (props.isSquare ? '100vw' : '560px')}
    }
  `,
};

export default Style;
