import React, { useState } from 'react';

import classNames from 'classnames';
import {
  wrap, navLayout, navItem, navFocus, contentsLayout,
} from './style.module.css';
import DATA from '../../data';
import useBrowserWidth from '../../hooks/useBrowserWidth';

const Process = () => {
  const [dataIndex, setDataIndex] = useState(0);
  const browserWidth = useBrowserWidth();

  return (
    <article className={wrap}>
      <ul className={navLayout}>
        {DATA.RECRUIT_PROCESS.map((item, index) => (
          <li
            key={`process_nav_${item.label}`}
            className={classNames(navItem, { [`${navFocus}`]: dataIndex === index })}
            onClick={() => { setDataIndex(index); }}
          >
            { browserWidth.isUnderSize(DATA.SCREEN_SIZE.TABLET) ? index + 1 : item.label }
          </li>
        ))}
      </ul>
      <div className={contentsLayout}>
        <h1>{DATA.RECRUIT_PROCESS[dataIndex].label}</h1>
        <h2>{DATA.RECRUIT_PROCESS[dataIndex].title}</h2>
        <p dangerouslySetInnerHTML={{ __html: DATA.RECRUIT_PROCESS[dataIndex].description }} />
      </div>
    </article>
  );
};

export default Process;
