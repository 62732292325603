import React from 'react';

import {
  wrap, title, list, link as styledLink, item,
} from './style.module.css';
import sendGAEvent from '../../../utils/event/core';
import { GA_ACTION, GA_CATEGORY } from '../../../utils/event/data';

const onClick = (jobTitle) => () => {
  // eslint-disable-next-line no-undef
  fbq('trackCustom', 'move_apply');
  sendGAEvent(
    GA_CATEGORY.JD.APPLY,
    GA_ACTION.LINK(jobTitle),
    jobTitle,
  );
};

function JobList({ job }) {
  // eslint-disable-next-line react/destructuring-assignment
  const { department, data } = job;

  return (
    <div id={department} className={wrap}>
      <h4 className={title}>{department}</h4>
      <ul className={list}>
        {
          data.map(({ url, title: jobTitle }) => (
            <li key={`${department}-${jobTitle}`} className={item}>
              { /* eslint-disable react/jsx-no-target-blank */ }
              <a
                href={url}
                target="_blank"
                onClick={onClick(jobTitle)}
                className={styledLink}
              >
                {jobTitle}
              </a>
            </li>
          ))
        }
      </ul>
    </div>
  );
}

export default JobList;
