import React from 'react';

import classNames from 'classnames';
import {
  wrap, backgroundEffect, layout, subTitle as styledSubTitle, title as styledTitle,
} from './style.module.css';
import Style from './index.style';

const Hero = ({
  subTitle,
  title,
  cover,
  mCover,
  isSquareLayout = false,
}) => (
  // eslint-disable-next-line react/jsx-pascal-case
  <Style.section
    className={wrap}
    backgroundPCImage={cover}
    backgroundMobileImage={mCover}
    isSquare={isSquareLayout}
  >
    <div className={backgroundEffect} />
    <div className={classNames('container-md', layout)}>
      <p className={styledSubTitle}>
        {subTitle}
      </p>
      <h1
        className={styledTitle}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </div>
  </Style.section>
);

export default Hero;
