import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';
import Jobs from '../containers/Jobs';

const JobsPage = () => (
  <Layout>
    <Head
      title="뱅크샐러드 | Jobs"
      description="현재 모집 중인 다양한 직군의 채용 공고를 확인하고 지원하세요!"
      keywords={['뱅크샐러드', '채용', '채용공고', 'TO', '지원', '개발자', '디자이너', '엔지니어', '마케터', '기획자', '뱅크샐러드 지원하기', '레이니스트', '마이데이터', '핀테크', '스타트업']}
    />
    <Jobs />
  </Layout>
);

export default JobsPage;
