import React from 'react';

import { Element } from 'react-scroll';
import { wrap } from './style.module.css';
import Departments from '../Departments';
import Info from '../../../components/Info';
import JobList from '../JobList';
import useOpeningJobs from './use-opening-jobs';

function JobOpening() {
  const { openingJobs } = useOpeningJobs();

  return (
    <section
      id="jobList"
      className={wrap}
    >
      <Element
        name="jobList"
      >
        <div className="container-md">
          <Info
            subTitle="Job openings"
            title="진행 중인 공고"
            isFullWidth
          />
          <Departments openingJobs={openingJobs} />
          {openingJobs.map((job) => <JobList job={job} key={job.department} />)}
        </div>
      </Element>
    </section>
  );
}

export default JobOpening;
