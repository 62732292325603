import { useEffect, useState } from 'react';
import axios from 'axios';

/**
 * Greeting API가 Staging이 존재 하지 않음
 *
 * @link
 * Proxy API
 * https://github.com/banksalad/enterprise-web/blob/main/apps/proxy/app/api/greeting/published-openings/route.ts#L10
 */
const URL = 'https://www.banksalad.com/proxy/api/greeting/published-openings';

const useOpeningJobs = () => {
  const [openingJobs, setOpeningJobs] = useState([]);

  useEffect(() => {
    (async () => {
      const { data: { jobs } } = await axios.get(URL);

      setOpeningJobs(jobs);
    })();
  }, []);

  return { openingJobs };
};

export default useOpeningJobs;
