import React from 'react';

import { wrap } from './style.module.css';
import Info from '../../../components/Info';
import Process from '../../../components/Process';

const RecruitProcess = () => (
  <section className={wrap}>
    <div className="container-md">
      <Info
        subTitle="RECRUITMENT PROCESS"
        title="뱅크샐러드로<br />향하는 Journey"
        isFullWidth
      >
        MY DATA 산업을 선도하는 뱅크샐러드에서
        <br />
        데이터로 개인이 가장 똑똑해지는 세상을
        <br className="onlyPC" />
        함께 만들어 나가실 분을 모십니다.
      </Info>
      <Process />
    </div>
  </section>
);

export default RecruitProcess;
