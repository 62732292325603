import React from 'react';

import Hero from '../../components/Hero';
import RecruitProcess from './RecruitProcess';
import JobOpening from './JobOpening';
import IMG_HERO from '../../images/jobs/main_hero.png';
import IMG_M_HERO from '../../images/jobs/m_main_hero.png';

function Jobs({ ...props }) {
  return (
    <div
      {...props}
    >
      <Hero
        subTitle="Join us"
        title="뱅크샐러드<br />채용 공고를 확인해보세요!"
        cover={IMG_HERO}
        mCover={IMG_M_HERO}
      />
      <div className="container-md">
        <RecruitProcess />
        <JobOpening />
      </div>
    </div>
  );
}

export default Jobs;
