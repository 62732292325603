import React from 'react';

import { Link } from 'gatsby';
import { item, link, list } from './style.module.css';
import sendGAEvent from '../../../utils/event/core';
import { GA_ACTION, GA_CATEGORY } from '../../../utils/event/data';

const onClick = (department) => () => (
  sendGAEvent(
    GA_CATEGORY.JD.NAV,
    GA_ACTION.LINK(department),
    department,
  )
);

function Departments({ openingJobs }) {
  return (
    <nav>
      <ul className={list}>
        {
        // eslint-disable-next-line react/destructuring-assignment
        openingJobs.map(({ department }) => (
          <li className={item} key={department}>
            <Link
              to={`#${department}`}
              className={link}
              onClick={onClick(department)}
            >
              {department}
            </Link>
          </li>
        ))
      }
      </ul>
    </nav>
  );
}

export default Departments;
